@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  /* display: flex; */
  background: #ffffff;
  @media (--viewportMedium) {
    position: relative;
    /* padding-top: var(--topbarHeightDesktop); */
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 18px 0 0 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
    padding: 23px 0 0 0;
  }
}

.error {
  /* color: var(--failColor); */
  color: var(--matterColorAnti);

  margin: auto;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 24px;
  font-size: 16px;
  @media (min-width: 320px) and (max-width: 767.98px) {
    font-size: 14px;
  }
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    /* padding: 0 24px; */
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    /* padding: 0 36px; */
  }
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  /* padding: 50px;
  align-items: center; */
  @media (max-width: 767.98px) {
    margin-top: 50px;
    width: 100%;
  }
}
.filterInnerContainer {
  @media (max-width: 575.98px) {
    display: none;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}
.pageLoading {
  justify-content: center;
  display: flex;
  min-height: 400px;
  align-items: center;
}
.searchListingsPanel {
  flex-grow: 1;

  margin: 50px 50px;
  @media (min-width: 320px) and (max-width: 991.98px) {
    margin: 50px 20px;
  }
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}
.searchPageContent {
  display: flex;
  align-items: flex-start;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.filterContainer {
  flex-basis: 20%;
  /* max-width: 409px; */
  margin-left: 50px;
  padding: 50px 10px;
  background: #f9fcff;
  /* border: 1px solid blue; */
  /* @media (max-width: 767px) {
    display: none;
  } */
  @media (max-width: 767px) {
    /* width: 90%; */
    width: 100%;

    margin-left: 0;
    display: flex;
    align-items: flex-start;
    /* padding: 0 10px; */
    padding: 0 0px;

    flex-direction: column;
    /* position: absolute; */
    position: fixed;
    z-index: 8;
    /* top: 200px; */
    top: 144px;

    /* border-radius: 20px; */
    /* left: 5%; */
    /* right: 5%; */
    box-shadow: 0 2px 14px #0000004a;
  }
}
.mobileFilterContainer {
  flex-basis: 20%;
  /* max-width: 409px; */
  margin-left: 50px;
  padding: 50px 10px;
  background: #f9fcff;
  /* border: 1px solid blue; */
  /* @media (max-width: 767px) {
    display: none;
  } */
  @media (max-width: 767px) {
    width: 90%;
    margin-left: 0px;
    display: flex;
    align-items: flex-start;
    padding: 0 10px;
    flex-direction: column;
    /* position: absolute; */
    position: fixed;

    z-index: 8;
    /* top: 200px; */
    top: 125px;

    border-radius: 20px;
    left: 5%;
    right: 5%;
    box-shadow: 0 2px 14px #0000004a;
  }
}
.filteCategorysecContainer {
  position: sticky;
  /* top: 130px; */
  top: 122px;

  border: none;
  background: transparent;
  box-shadow: none;
  /* width: 90%; */
  width: 100%;

  padding: 50px 10px;
  @media (max-width: 767px) {
    padding: 0;
  }
}
.optionContainer {
  margin-left: 20px;
}

.subCategoryTray {
  margin-left: 30px;
}
.subCatLabelDiv {
  margin-top: 5px;
  padding: 10px 10px;
  &:hover {
    background-color: var(--marketplaceColorLight);
    border-radius: 10px;
    /* color: #ffffff; */
    cursor: pointer;
  }
}
.subCatLabel {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  /* &:hover {
    background-color: var(--marketplaceColorLight);
    border-radius: 10px;
  } */
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 21px;
  }
}
.optionHeadingBtnDiv {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 10px 10px;
  line-height: 30px;
  &:hover {
    background-color: var(--marketplaceColorLight);
    /* color: #ffffff; */
    border-radius: 10px;
    cursor: pointer;
  }
}
.fliterLabel {
  font-size: 20px;
  font-weight: 600;
  display: block;
  max-width: 200px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.catLabel {
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.catLabelDiv {
  display: flex;
  align-items: center;
  margin-top: 5px;
  min-height: 47px;
  padding: 10px 10px;
  &:hover {
    background-color: var(--marketplaceColorLight);
    color: var(--matterColorText);
    border-radius: 10px;
    cursor: pointer;
  }
}
.showHideBtn {
  margin-left: auto !important;
  border: none;
  margin-left: 5px;
  fill: none;
  & path {
    &:hover {
      stroke: #ffffff;
    }
  }
  @media (max-width: 767px) {
    height: 6px;
  }
}
.hDivider {
  display: block;
  border-bottom: 1px solid var(--matterColorDivider);
  margin-top: 5px;
}
.arrowDiv {
  margin-left: auto;
}
.searchErrorDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 50px; */
  margin: 50px;
  text-align: center;
}
.noListingDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 50px; */
  margin: 50px;
  text-align: center;
}
.listSvg {
  height: 54px;
  width: 54px;
  /* stroke: #bfbfbf; */

  & path {
    stroke: #bfbfbf;
  }
}
.selectedFilter {
  display: flex;
  margin: 0 10px;
  padding: 10px;
  border: 1px solid var(--matterColorDark);
  align-items: center;
  text-transform: uppercase;
}
.selectedFilters {
  /* margin: 10px 50px 0 50px; */
  margin: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
}
.filterDiv {
  display: flex;
  margin: 10px 0px 20px 0px;
}
.filterBtns {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #e9f3ff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
  /* min-width: 121px; */
  min-height: 40px;
  font-size: 16px;
  font-weight: 400;
  & svg {
    /* margin-left: auto; */
    fill: none;
    height: 16px;
    width: 16px;
    margin: 0 0 0 10px;
  }
  & span {
    text-transform: capitalize;
    margin-left: 3px;
    font-weight: 500;
  }
}
.resetAllButton {
  border: none;
  cursor: pointer;
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: underline;
  }
}
.activeCatLabel {
  /* background-color: var(--marketplaceColor); */
  border-radius: 10px;
  /* color: #ffffff; */
  color: var(--marketplaceColor);
  & div {
    & svg {
      & path {
        stroke: var(--marketplaceColor);
      }
    }
  }
}
.filterSectionContainer {
  margin: 10px 0;
  @media (max-width: 767px) {
    /* right: 20px; */
    box-shadow: none !important;
    border-radius: 0 !important;
  }
}
.filterInnerContainer {
  @media (max-width: 767px) {
    left: 20px;
    /* width: 90%; */
  }
}

.filterInnerContainer,
.filterSectionContainer {
  /* border-bottom: 1px solid #000; */
  border-radius: 20px;
  background: #f9fcff;
  box-shadow: 0 2px 14px #0000004a;
  padding: 5px;
  @media (max-width: 767px) {
    border-radius: 20px;
    background: #f9fcff;
    box-shadow: 0 2px 14px #0000004a;
    padding: 5px;
    flex-basis: 50%;
    width: 100%;
    /* position: absolute; */
    z-index: 9;
    /* border: none; */
    display: none;
  }
}

.activated {
  display: block;

  @media (max-width: 767.98px) {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.searchfiltertab1 {
  flex-basis: 90%;
  border-right: 2px solid #e9f3ff;
  display: flex;
  align-items: center;
  padding-right: 15px !important;
}

.searchfiltertab2 {
  flex-basis: 10%;
  & svg {
    height: 16px;
    width: 16px;
    & path {
      fill: transparent;
    }
  }
}

.searchfiltertab1,
.searchfiltertab2 {
  padding: 12px 25px;
  font-size: 14px;
}
.flttbsMob {
  background: red;
}

.activatedfltrTab {
  border-bottom: 4px solid var(--marketplaceColor);
}

.mobilefilterremain {
  width: 100%;
  & .searchFiltersPrimary {
    display: flex;
  }
  & .filterSectionContainer {
    position: initial;
  }
}

.optionWrapper {
  width: 100%;
  min-width: 330px;
  /* @media (max-width: 767.98px) {
    width: 90%;
  } */
}

.mobiletopfiltab {
  display: flex;
  position: sticky;
  top: 122px;
  background: #f9fcff;
  z-index: 9;
  /* margin-top: -6px; */
  width: 100%;
}
